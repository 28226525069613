<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('经纪人/服务者.经纪人.代理人姓名')" prop="name">
                <a-input
                  v-model="queryParam.name"
                  :placeholder="$t('经纪人/服务者.经纪人.请输入代理人姓名')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('经纪人/服务者.经纪人.代理人所在公司')" prop="company">
                  <a-input
                    v-model="queryParam.company"
                    :placeholder="$t('经纪人/服务者.经纪人.请输入代理人所在公司')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('经纪人/服务者.经纪人.代理人电话')" prop="agencyMobile">
                  <a-input
                    v-model="queryParam.agencyMobile"
                    :placeholder="$t('经纪人/服务者.经纪人.请输入代理人电话')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('经纪人/服务者.经纪人.业主姓名')" prop="ownerName">
                  <a-input
                    v-model="queryParam.ownerName"
                    :placeholder="$t('经纪人/服务者.经纪人.请输入业主姓名')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('经纪人/服务者.经纪人.电话号码')" prop="mobile">
                  <a-input
                    v-model="queryParam.mobile"
                    :placeholder="$t('经纪人/服务者.经纪人.请输入电话号码')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:broker:add']">-->
        <!--          <a-icon type="plus" />新增-->
        <!--        </a-button>-->
        <!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:broker:edit']">-->
        <!--          <a-icon type="edit" />修改-->
        <!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:broker:remove']">
          <a-icon type="delete" />{{$t('通用.删除')}}
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:broker:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:broker:edit']" />
          <a @click="$refs.createForm.getDetails(record, undefined)" v-hasPermi="['user:broker:edit']">
            <a-icon type="edit" />{{$t('通用.详情')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['user:broker:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:broker:edit']">
            <a-icon type="edit" />{{$t('通用.审核')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['user:broker:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['user:broker:remove']"> <a-icon type="delete" />{{$t('通用.删除')}} </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageBroker, listBroker, delBroker } from '@/api/user/broker'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Broker',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        name: null,
        avatar: null,
        company: null,
        age: null,
        agencyMobile: null,
        email: null,
        project: null,
        ownerName: null,
        mobile: null,
        city: null,
        postal: null,
        invitation: null,
        status: null,
        reason: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        // {
        //   title: '主键',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '用户名称',
        //   dataIndex: 'userName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '代理人姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '头像',
        //   dataIndex: 'avatar',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '代理人所在公司',
          dataIndex: 'company',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '所在公司年份',
          dataIndex: 'age',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '代理人电话',
          dataIndex: 'agencyMobile',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '代理人邮件',
        //   dataIndex: 'email',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '推荐项目地址',
        //   dataIndex: 'project',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '业主姓名',
        //   dataIndex: 'ownerName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '电话号码',
        //   dataIndex: 'mobile',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '城市',
        //   dataIndex: 'city',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '邮政编码',
        //   dataIndex: 'postal',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '邀请码',
          dataIndex: 'invitation',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '待审核'
            } else if (text == 1) {
              return '审核通过'
            } else {
              return '审核驳回'
            }
          },
        },
        {
          title: '审核驳回的情况下的拒绝原因',
          dataIndex: 'reason',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '插入时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
    this.updateColumns()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询经纪人列表 */
    getList() {
      this.loading = true
      pageBroker(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: undefined,
        name: undefined,
        avatar: undefined,
        company: undefined,
        age: undefined,
        agencyMobile: undefined,
        email: undefined,
        project: undefined,
        ownerName: undefined,
        mobile: undefined,
        city: undefined,
        postal: undefined,
        invitation: undefined,
        status: undefined,
        reason: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('服务管理.服务内容管理.确认删除所选中数据'),
        content: this.$t('服务管理.服务内容管理.当前选中编号为') + ids + this.$t('用户管理.的数据'),
        okText: this.$t('通用.确认'),
        cancelText: this.$t('通用.取消'),
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delBroker(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'user/userBroker/export',
            {
              ...that.queryParam,
            },
            `经纪人_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
     updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
         {
          title: "Agent's name",
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },

        {
          title: "Agent's company",
          dataIndex: 'company',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Company year',
          dataIndex: 'age',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Agent telephone',
          dataIndex: 'agencyMobile',
          ellipsis: true,
          align: 'center',
        },

        {
          title: 'Invitation code',
          dataIndex: 'invitation',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'audit status',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '待审核'
            } else if (text == 1) {
              return '审核通过'
            } else {
              return '审核驳回'
            }
          },
        },
        {
          title: 'the reason for refusal',
          dataIndex: 'reason',
          ellipsis: true,
          align: 'center',
        },

        {
          title: 'operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
        ],
        'zh-CN': [
         {
          title: '代理人姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },

        {
          title: '代理人所在公司',
          dataIndex: 'company',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '所在公司年份',
          dataIndex: 'age',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '代理人电话',
          dataIndex: 'agencyMobile',
          ellipsis: true,
          align: 'center',
        },

        {
          title: '邀请码',
          dataIndex: 'invitation',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '待审核'
            } else if (text == 1) {
              return '审核通过'
            } else {
              return '审核驳回'
            }
          },
        },
        {
          title: '审核驳回的情况下的拒绝原因',
          dataIndex: 'reason',
          ellipsis: true,
          align: 'center',
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
