<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle == '详情' ? $t('通用.详情') : $t('通用.修改') }}</b>
    </a-divider>
    <a-form-model v-if="this.formType == 1" ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户名称" prop="userName" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.代理人姓名')" prop="name" >
        <a-input :disabled=true v-model="form.name" :placeholder="$t('经纪人/服务者.经纪人.请输入代理人姓名')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.头像')" prop="avatar" >
        <a-input :disabled=true v-model="form.avatar" :placeholder="$t('经纪人/服务者.经纪人.头像')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.代理人所在公司')" prop="company" >
        <a-input :disabled=true v-model="form.company" :placeholder="$t('经纪人/服务者.经纪人.代理人所在公司')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.所在公司年份')" prop="age" >
        <a-input :disabled=true v-model="form.age" :placeholder="$t('经纪人/服务者.经纪人.请输入所在公司年份')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.代理人电话')" prop="agencyMobile" >
        <a-input :disabled=true v-model="form.agencyMobile" placeholder="请输入代理人电话" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.代理人邮件')" prop="email" >
        <a-input :disabled=true v-model="form.email" placeholder="请输入代理人邮件" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.推荐项目地址')" prop="project" >
        <a-input :disabled=true v-model="form.project" placeholder="请输入推荐项目地址" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.业主姓名')" prop="ownerName" >
        <a-input :disabled=true v-model="form.ownerName" placeholder="请输入业主姓名" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.电话号码')" prop="mobile" >
        <a-input :disabled=true v-model="form.mobile" placeholder="请输入电话号码" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.城市')" prop="city" >
        <a-input :disabled=true v-model="form.city" placeholder="请输入城市" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.邮政编码')" prop="postal" >
        <a-input :disabled=true v-model="form.postal" placeholder="请输入邮政编码" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.邀请码')" prop="invitation" >
        <a-input :disabled=true v-model="form.invitation" placeholder="请输入邀请码" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.审核状态')" prop="status" >
        <a-radio-group :disabled=true name="radioGroup" :default-value="0" v-model="form.status">
          <a-radio :value=0>{{$t('经纪人/服务者.经纪人.未审核')}}</a-radio>
          <a-radio :value=1>{{$t('经纪人/服务者.经纪人.已通过')}}</a-radio>
          <a-radio :value=-1>{{$t('经纪人/服务者.经纪人.已通过')}}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.经纪人.审核驳回的情况下的拒绝原因')" prop="reason" >
        <a-input :disabled=true v-model="form.reason" placeholder="请输入审核驳回的情况下的拒绝原因" />
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <div class="bottom-control">-->
<!--        <a-space>-->
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
<!--          <a-button type="dashed" @click="cancel">-->
<!--            取消-->
<!--          </a-button>-->
<!--        </a-space>-->
<!--      </div>-->
    </a-form-model>

    <a-form-model v-if="this.formType == 2" ref="form" :model="form" :rules="rules">
      <a-form-model-item  :label="$t('通用.审核')" prop="status" >
        <a-radio-group name="radioGroup" :default-value="0" v-model="form.status">
          <a-radio value=1>{{$t('经纪人/服务者.经纪人.通过')}}</a-radio>
          <a-radio value=-1>{{$t('经纪人/服务者.经纪人.拒绝')}}</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="form.status == -1" label="审核拒绝原因" prop="rejectReason" >
        <a-textarea v-model="form.reason" placeholder="请输入审核拒绝原因" :auto-size="{ minRows: 3, maxRows: 10 }"></a-textarea>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>

  </a-drawer>
</template>

<script>
import { getBroker, addBroker, updateBroker } from '@/api/user/broker'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        name: null,

        avatar: null,

        company: null,

        age: null,

        agencyMobile: null,

        email: null,

        project: null,

        ownerName: null,

        mobile: null,

        city: null,

        postal: null,

        invitation: null,

        status: 0,

        reason: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '代理人姓名不能为空', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '头像不能为空', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '代理人所在公司不能为空', trigger: 'blur' }
        ],
        age: [
          { required: true, message: '所在公司年份不能为空', trigger: 'blur' }
        ],
        agencyMobile: [
          { required: true, message: '代理人电话不能为空', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '代理人邮件不能为空', trigger: 'blur' }
        ],
        project: [
          { required: true, message: '推荐项目地址不能为空', trigger: 'blur' }
        ],
        ownerName: [
          { required: true, message: '业主姓名不能为空', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '电话号码不能为空', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '城市不能为空', trigger: 'blur' }
        ],
        postal: [
          { required: true, message: '邮政编码不能为空', trigger: 'blur' }
        ],
        invitation: [
          { required: true, message: '邀请码不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '审核状态，0-待审核，1-审核通过，-1审核驳回不能为空', trigger: 'blur' }
        ],
        reason: [
          { required: true, message: '审核驳回的情况下的拒绝原因不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        name: null,
        avatar: null,
        company: null,
        age: null,
        agencyMobile: null,
        email: null,
        project: null,
        ownerName: null,
        mobile: null,
        city: null,
        postal: null,
        invitation: null,
        status: 0,
        reason: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },

    getDetails(row,ids){
      this.reset()
      this.formType = 1
      const id = row ? row.id : ids
      getBroker({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },

    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBroker({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBroker(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBroker(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
