import request from '@/utils/request'


// 查询经纪人列表
export function listBroker(query) {
  return request({
    url: '/user/userBroker/list',
    method: 'get',
    params: query
  })
}

// 查询经纪人分页
export function pageBroker(query) {
  return request({
    url: '/user/userBroker/page',
    method: 'get',
    params: query
  })
}

// 查询经纪人详细
export function getBroker(data) {
  return request({
    url: '/user/userBroker/detail',
    method: 'get',
    params: data
  })
}

// 新增经纪人
export function addBroker(data) {
  return request({
    url: '/user/userBroker/add',
    method: 'post',
    data: data
  })
}

// 修改经纪人
export function updateBroker(data) {
  return request({
    url: '/user/userBroker/edit',
    method: 'post',
    data: data
  })
}

// 删除经纪人
export function delBroker(data) {
  return request({
    url: '/user/userBroker/delete',
    method: 'post',
    data: data
  })
}
